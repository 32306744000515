import React from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {connect} from 'react-redux';
import axios from 'axios';

import AgencyEditComponent from 'components/Agencies/Edit';
import Main from 'components/Layout/Main';

import {successToast, errorToast} from 'actions';
import routes from 'routes';
import AccessControl from 'components/RBAC/AccessControl';
import permissions from 'permissions';
import Forbidden from 'components/Forbidden';
import useAgency from 'api/useAgency';

function AgencyEditContainer(props) {

    const params = useParams();
    let history = useHistory();

    // Fetch agency
    const { isLoading, agency } = useAgency(params.agencyId);

    // Update agency
    const updateAgency = async (agency) => {
        try {
            await axios.post(`${routes.agencies.endpoint}/${params.agencyId}`, agency);
            props.successToast(`${agency.name} successfully updated!`);
            history.push(routes.agencies.path);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Main>
            <AccessControl allowedPermissions={[permissions.agencies.update]} noAccess={() => <Forbidden/>}>
                <AgencyEditComponent agency={agency} updateAgency={updateAgency}
                                     brands={agency === null ? [] : agency.brands} isLoadingAgency={isLoading}/>
            </AccessControl>
        </Main>
    );
}

const mapDispatchToProps = dispatch => ({
    successToast: msg => dispatch(successToast(msg)),
    errorToast: msg => dispatch(errorToast(msg)),
});

export default connect(null, mapDispatchToProps)(AgencyEditContainer);
