import React, { useEffect, useState } from 'react';

import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { withAuthenticator } from 'aws-amplify-react';
import Amplify, { Auth, Hub } from 'aws-amplify';
import { connect } from 'react-redux';
import axios from 'axios';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ReportsSearchContainer from 'containers/Reports/Search';
import NonComplianceReportContainer from './containers/Reports/NonComplianceReport';
import ViolationsContainer from './containers/Violations/List';
import ViolationContainer from './containers/Violations/Violation';
import BrandsContainer from './containers/Brands/List';
import CreateBrandContainer from 'containers/Brands/Create';
import BrandDetailsContainer from 'containers/Brands/Details';
import BrandsSearchTermsContainer from 'containers/Brands/Details/SearchTerms/SearchTermsList';
import BrandsGenericTermsContainer from 'containers/Brands/Details/SearchTerms/GenericTermsList';
import BrandsOffLabelTermsContainer from 'containers/Brands/Details/SearchTerms/OffLabelTermsList';
import BrandsOnLabelTermsContainer from 'containers/Brands/Details/SearchTerms/OnLabelTermsList';
import AgenciesContainer from './containers/Agencies/List';
import AgencyEditContainer from './containers/Agencies/Edit';
import CreateAgencyContainer from './containers/Agencies/Create';
import UsersListContainer from './containers/Users/List';
import CreateUserContainer from './containers/Users/Create';
import EditUserContainer from './containers/Users/Edit';

import {setCurrentUser, setUnreadViolationsCount, setViolations} from './actions'
import theme from './theme';
import routes from 'routes';
import useViolations from "./api/useViolations";
import useViolationsCount from "./api/useViolationsCount";

// Connect to Cognito.
Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
  }
});

const listener = (data) => {
  switch (data.payload.data.code) {
    case 'PasswordResetRequiredException':
      const email = data.payload.message.split(" ")[0];
      Auth.forgotPassword(email)
      .then(data => console.log('forgotPassword', data))
      .catch(err => console.log('forgotPassword error', err));
  }
};

Hub.listen('auth', listener);

function App(props) {

  const [loading, setLoading] = useState(true);

  // Set default header which will be sent with every axios request.
  useEffect(() => {
    Auth.currentSession()
    .then(data => {
      axios.defaults.headers.common['Authorization'] = `Bearer ${data.getIdToken().getJwtToken()}`;
      axios.get(`${routes.users.endpoint}/current`).then(response => {
        props.setCurrentUser(response.data.data);
        setLoading(false);
      }).catch(error => {
        console.log(error);
        if (error.response && error.response.status >= 400) {
          Auth.signOut().then(() => {
            //Sign out complete
            console.log("Signed out due to response status on current user endpoint.")
          })
          .catch(err => {
            console.log("Error while signing out.", err)
          });
        }
      });
    })
    .catch(err => console.log(err));
  }, []);

  useViolations({dependencies: loading});
  useViolationsCount({dependencies: loading})
  if (loading) return (<LinearProgress/>);


  return (
    <ThemeProvider theme={theme}>
      <ToastContainer
        position='top-right'
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <CssBaseline/>
      <Router>
        <Switch>
          <Route path={routes.reports.path} exact>
            <ReportsSearchContainer/>
          </Route>
          <Route path={`${routes.reports.path}*`} exact>
            <NonComplianceReportContainer/>
          </Route>
          <Route path={routes.agencies.path} exact>
            <AgenciesContainer/>
          </Route>
          <Route path={`${routes.agencies.path}/new`} exact>
            <CreateAgencyContainer/>
          </Route>
          <Route path={`${routes.agencies.path}/:agencyId`} exact>
            <AgencyEditContainer/>
          </Route>
          <Route path={routes.brands.path} exact>
            <BrandsContainer/>
          </Route>
          <Route path={`${routes.brands.path}/new`} exact>
            <CreateBrandContainer/>
          </Route>
          <Route path={`${routes.brands.path}/:brandId`} exact>
            <BrandDetailsContainer/>
          </Route>
          <Route path={`${routes.brands.path}/:brandId/${routes.searchTerms.searchTerms.path}`} exact>
            <BrandsSearchTermsContainer/>
          </Route>
          <Route path={`${routes.brands.path}/:brandId/${routes.searchTerms.genericTerms.path}`} exact>
            <BrandsGenericTermsContainer/>
          </Route>
          <Route path={`${routes.brands.path}/:brandId/${routes.searchTerms.offLabelTerms.path}`} exact>
            <BrandsOffLabelTermsContainer/>
          </Route>
          <Route path={`${routes.brands.path}/:brandId/${routes.searchTerms.onLabelTerms.path}`} exact>
            <BrandsOnLabelTermsContainer/>
          </Route>
          <Route path={routes.users.path} exact>
            <UsersListContainer/>
          </Route>
          <Route path={`${routes.users.path}/new`} exact>
            <CreateUserContainer/>
          </Route>
          <Route path={`${routes.users.path}/:userId`} exact>
            <EditUserContainer/>
          </Route>
          <Route path={routes.violations.path} exact>
            <ViolationsContainer/>
          </Route>
          <Route path={`${routes.violations.path}/:violationId`} exact>
            <ViolationContainer/>
          </Route>
          <Redirect to={routes.reports.path}/>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

// withAuthenticator() provides all pages related to login and enforces authentication.
export default connect(null, {
  setCurrentUser,
  setUnreadViolationsCount,
  setViolations
})(withAuthenticator(App, { usernameAttributes: 'email' }));
