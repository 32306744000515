import React from 'react';

import UsersTableComponent from 'components/Users/List';
import Main from 'components/Layout/Main';
import permissions from 'permissions';
import AccessControl from 'components/RBAC/AccessControl';
import Forbidden from 'components/Forbidden';
import useUsers from 'api/useUsers';

function UsersListContainer() {

    const { isLoading, users } = useUsers({}, [], true);

    return (
        <Main>
            <AccessControl allowedPermissions={[permissions.users.list]} noAccess={() => <Forbidden />}>
                <UsersTableComponent users={users} isLoading={isLoading} />
            </AccessControl>
        </Main>
    );
}

export default UsersListContainer;
