import React from 'react';
import PropTypes from 'prop-types';
import {Chip, TextField} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

function SearchStrategistSelect(props) {

    const {strategist, setStrategist} = props;

    const handleStrategistChange = (event, newValue, reason) => {
        setStrategist(newValue.filter(option => option.id !== -1));
    };

    const handleSearchStrategistDelete = (event, item) => {
        setStrategist(strategist.filter(option => option.id !== item.id));
    };

    return (
        <>
            <Autocomplete
                multiple
                id='strategist-select'
                loading={props.userLoading}
                options={props.users}
                getOptionLabel={(option) => option.name}
                autoHighlight
                getOptionSelected={
                    (option, searchStrategist) => {
                        return option.id === searchStrategist.id
                    }
                }
                filterSelectedOptions
                value={strategist.length === 0 ? [{ id: -1, name: 'All' }] : strategist}
                onChange={
                    (event, newValue, reason) => handleStrategistChange(event, newValue, reason)
                }
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            {...(option.id !== -1 && getTagProps({index}))}
                            key={option.id}
                            label={option.name}
                            size='small'
                            {...(option.id !== -1 && {onDelete: ((event) => handleSearchStrategistDelete(event, option))})}
                        />)
                    )
                }
                renderInput={params => (
                    <TextField {...params} label='Search Strategist'/>
                )}
            />
        </>
    );
}

SearchStrategistSelect.propTypes = {
    strategist: PropTypes.array,
    setStrategist: PropTypes.func,
};

export default SearchStrategistSelect;
