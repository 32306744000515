import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import routes from 'routes';
import permissions from 'permissions';
import rules from 'rbac-rules';
import {check} from 'components/RBAC/AccessControl';
import {setUnreadViolationsCount} from '../actions';

export default ({dependencies, condition=true} = {}) => {
    const {currentUser} = useSelector(state => state.users);
    const dispatch = useDispatch()

    useEffect(() => {
        if (currentUser !== undefined && check(rules, currentUser.role, [permissions.violations.list]) && condition) {
            axios.head(routes.violations.endpoint).then(response => {
                dispatch(setUnreadViolationsCount(response.headers["x-unread-count"]));
            });
        }
    }, [dependencies]);

}
