import React from 'react';
import PropTypes from 'prop-types';
import {Chip, TextField} from '@material-ui/core';
import Autocomplete from "@material-ui/lab/Autocomplete";

function SearchTermsSelect(props) {

    const {brand, searchTermFilter, setSearchTermFilter, classes} = props;

    const brandIds = brand.flatMap(brand => (brand !== null) ? brand.id : [{id: -1, url: 'All'}]);

    const {searchTerm} = props;
    const termOptions = searchTerm !== null ?
        searchTerm.filter(option =>
            brandIds.includes(option.brandId) || option.brandId === null || brandIds.length === 0
        ) : []

    const handleSearchTermFilterChange = (event, newValue, reason) => {
        setSearchTermFilter(newValue.filter(option => option.id !== -1));
    };

    const handleSearchTermFilterDelete = (event, item) => {
        setSearchTermFilter(searchTermFilter.filter(option => option.id !== item.id));
    };

    return (
        <>
            <Autocomplete
                multiple
                id='search-term-select'
                loading={props.searchTermLoading}
                options={termOptions}
                getOptionLabel={(option) => option.name}
                autoHighlight
                getOptionSelected={
                    (option, searchTermFilter) => {
                        return option.id === searchTermFilter.id
                    }
                }
                filterSelectedOptions
                value={searchTermFilter.length === 0 ? [{id: -1, name: 'All'}] : searchTermFilter}
                onChange={
                    (event, newValue, reason) => handleSearchTermFilterChange(event, newValue, reason)
                }
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                            <Chip
                                {...(option.id !== -1 && getTagProps({index}))}
                                key={option.id}
                                label={option.name}
                                size='small'
                                {...(option.id !== -1 && {onDelete: ((event) => handleSearchTermFilterDelete(event, option))})}
                            />
                        )
                    )}
                renderInput={params => (
                    <TextField {...params} label='Select Search Terms'/>
                )}
            />
        </>
    );
}

SearchTermsSelect.propTypes = {
    brand: PropTypes.array,
    searchTermFilter: PropTypes.any,
    setSearchTermFilter: PropTypes.func,
    allSearchTerms: PropTypes.array
};

export default SearchTermsSelect;
