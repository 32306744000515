import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import axios from 'axios';

import TermsTableComponent from 'components/Brands/Details/SearchTerms/List';
import searchTerms from 'components/Brands/Details/SearchTerms/searchTerms';
import Main from 'components/Layout/Main';

import {successToast, errorToast} from 'actions';
import routes from 'routes';
import AccessControl from 'components/RBAC/AccessControl';
import permissions from 'permissions';
import Forbidden from 'components/Forbidden';
import useBrand from 'api/useBrand';
import useSearchTerms from 'api/useSearchTerms';

function BrandsSearchTermsContainer(props) {

    let params = useParams();
    const [terms, setTerms] = useState(null);
    const [newTerms, setNewTerms] = useState([]);

    // Fetch the brand
    const { brand } = useBrand(params.brandId);

    // Fetch terms for the brand
    const { isLoading: isLoadingSearchTerms, terms: fetchedTerms } = useSearchTerms(params.brandId, [newTerms]);

    useEffect(() => {
        setTerms(fetchedTerms);
    });

    // Save terms
    const saveTerms = async ({terms, msg}) => {
        try {
            await axios.post(`${routes.brands.endpoint}/${params.brandId}/terms`, terms);
            setNewTerms(terms);
            props.successToast(msg);
        } catch (error) {
            console.log(error);
        }
    };

    // Deletes a term
    const deleteTerm = async ({term, msg}) => {
        try {
            await axios.post(`${routes.brands.endpoint}/${params.brandId}/terms/delete`, term);
            setTerms({...terms, searchTerms: terms.searchTerms.filter(value => value.id !== term.id)});
            setNewTerms(terms);
            props.successToast(msg);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Main>
            <AccessControl allowedPermissions={[permissions.brands.detail]} noAccess={() => <Forbidden/>}>
                <TermsTableComponent type={searchTerms.searchTerms}
                                     searchTerms={terms === null ? null : terms.searchTerms}
                                     isLoadingSearchTerms={isLoadingSearchTerms}
                                     deleteTerm={deleteTerm} brand={brand} saveTerms={saveTerms}
                                     count={terms === null ? 0 : terms.searchTermsCount}/>
            </AccessControl>
        </Main>
    );
}

const mapDispatchToProps = dispatch => ({
    successToast: msg => dispatch(successToast(msg)),
    errorToast: msg => dispatch(errorToast(msg))
});

export default connect(null, mapDispatchToProps)(BrandsSearchTermsContainer);
