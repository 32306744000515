import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';

import {
    Card,
    CardContent,
    Grid,
    makeStyles,
    Typography,
    Divider,
    IconButton
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import SearchTerms from 'components/Brands/Details/SearchTerms';
import routes from 'routes';
import {useSelector} from 'react-redux';
import {check} from '../../../../RBAC/AccessControl';
import rules from '../../../../../rbac-rules';
import permissions from '../../../../../permissions';
import MaterialUITable from '../../../../Custom/MaterialUITable';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4)
    },
    paperForm: {
        boxShadow: 'none',
        padding: theme.spacing(0)
    },
    title: {
        fontWeight: 700,
        textTransform: 'uppercase',
        color: theme.palette.primary.dark
    }
}));

function TermsTableComponent(props) {

    const classes = useStyles();
    let history = useHistory();
    const { currentUser } = useSelector(state => state.users);
    const canEdit = check(rules, currentUser.role, [permissions.brands.update.basic]);

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>

                <Grid item xs={12}>
                    {props.brand === null ? (
                        <Skeleton variant="text" animation="wave" width="50%">
                            <Typography variant='h3'>.</Typography>
                        </Skeleton>
                    ) : (
                        <Typography variant='h3'>
                            <IconButton aria-label='Back' edge='start' onClick={() => history.push(`${routes.brands.path}/${props.brand.id}`)}>
                                <KeyboardBackspaceIcon />
                            </IconButton>
                            {props.brand.brandName} {props.type.name}
                        </Typography>
                    )}
                </Grid>

                <Grid item xs={12}>
                    <Card variant='outlined'>

                        <CardContent className={classes.cardContent}>
                            <SearchTerms type={props.type} classes={classes} searchTerms={props.searchTerms}
                                         saveTerms={props.saveTerms} count={props.count} isLoadingSearchTerms={props.isLoadingSearchTerms}/>
                        </CardContent>

                        <Divider />

                        <CardContent className={classes.cardContent}>
                            <MaterialUITable
                                isLoading={props.isLoadingSearchTerms}
                                data={props.searchTerms ? props.searchTerms : []}
                                columns={[
                                    {title: 'Search Terms', field: 'term'},
                                    {
                                        title: 'Date Added', field: 'dateCreated',
                                        render: rowData => (format(new Date(rowData.dateCreated), "MMM d, yyyy 'at' h:mmaaaa")),
                                        customFilterAndSearch: (term, rowData) =>
                                            rowData.dateCreated === null ? false : (format(new Date(rowData.dateCreated), "MMM d, yyyy 'at' h:mmaaaa").toLowerCase()).includes(term.toLowerCase())
                                    },
                                    {
                                        title: 'Results',
                                        field: 'countForAllAdsFound',
                                        render: rowData => (rowData.countForAllAdsFound && rowData.countForAllAdsFound.toLocaleString()),
                                        customFilterAndSearch: (term, rowData) => term === rowData.countForAllAdsFound.toString()
                                    }
                                ]}
                                {...(canEdit &&
                                    {actions: [
                                            {
                                                icon: () => <span className='tableActionButton'>Remove</span>,
                                                tooltip: `Remove Term`,
                                                onClick: (event, rowData) => {
                                                    props.deleteTerm({term: {type: props.type.type, term: rowData.term, id: rowData.id}, msg: `${rowData.term} removed from ${props.type.name}`})
                                                }
                                            }
                                        ]}
                                )}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}

TermsTableComponent.propTypes = {
    type: PropTypes.object,
    title: PropTypes.string,
    searchTerms: PropTypes.array,
    isLoadingSearchTerms: PropTypes.bool,
    brand: PropTypes.object,
    saveTerms: PropTypes.func,
    deleteTerm: PropTypes.func,
    count: PropTypes.number
};

export default TermsTableComponent;
