import React from 'react';

import AgenciesTableComponent from 'components/Agencies/List';
import Main from 'components/Layout/Main';
import useAgencies from 'api/useAgencies';

import AccessControl from 'components/RBAC/AccessControl';
import permissions from 'permissions';
import Forbidden from 'components/Forbidden';

function AgenciesContainer() {

    const { isLoading, agencies } = useAgencies();

    return (
        <Main>
            <AccessControl allowedPermissions={[permissions.agencies.list]} noAccess={() => <Forbidden/>}>
                <AgenciesTableComponent agencies={agencies} isLoading={isLoading}/>
            </AccessControl>
        </Main>
    );
}

export default AgenciesContainer;
