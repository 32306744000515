import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import {Card, CardHeader, CardContent, Divider, Grid, makeStyles, Typography} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import SearchStrategists from './SearchStrategists';
import PharmaCompany from './PharmaCompany';
import SearchTerms from './SearchTerms';
import EmailAlerts from './EmailAlerts';
import DomainUrls from './DomainUrls';
import BrandName from './BrandName';
import AdAgency from './AdAgency';

import searchTerms from './SearchTerms/searchTerms';
import routes from 'routes';
import roles from 'roles';
import useUsers from 'api/useUsers';
import BingEnabled from './BingEnalbed';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4)
    },
    title: {
        fontWeight: 700,
        textTransform: 'uppercase',
        color: theme.palette.primary.dark
    }
}));

function BrandDetailsComponent(props) {

    const location = useLocation();
    const classes = useStyles();
    const { brand, updateBrand, saveBrand, agencies, isLoadingAgencies } = props;

    let params = new URLSearchParams();
    params.append('agencyId', brand ? brand.agencyId : '');
    params.append('role', roles.SEARCH_STRATEGIST.name);
    const { isLoading: isLoadingStrategists, users: strategists } = useUsers(params, [brand], true);

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>

                <Grid item xs={12} md={6}>
                    {!brand ? (
                        <Skeleton variant="text" animation="wave" width="100%"><Typography variant="h3">.</Typography></Skeleton>
                    ) : (
                        <BrandName brand={brand} classes={classes} updateBrand={updateBrand} saveBrand={saveBrand} />
                    )}
                </Grid>

                <Grid item xs={12}>
                    <Card>
                        <CardHeader title='Brand Details'/>
                        <Divider />
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                                    {!brand ? (
                                        <>
                                            <Skeleton variant="text" animation="wave" width="50%" />
                                            <Skeleton variant="text" animation="wave" width="100%" />
                                        </>
                                    ) : (
                                        <PharmaCompany classes={classes} brand={brand} updateBrand={updateBrand} saveBrand={saveBrand} />
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                                    {!brand ? (
                                        <>
                                            <Skeleton variant="text" animation="wave" width="50%" />
                                            <Skeleton variant="text" animation="wave" width="100%" />
                                        </>
                                    ) : (
                                        <SearchStrategists strategists={strategists} isLoadingStrategists={isLoadingStrategists}
                                                           classes={classes} brand={brand} updateBrand={updateBrand} saveBrand={saveBrand} />
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                                    {!brand ? (
                                        <>
                                            <Skeleton variant="text" animation="wave" width="50%" />
                                            <Skeleton variant="text" animation="wave" width="100%" />
                                        </>
                                    ) : (
                                        <AdAgency classes={classes} brand={brand} updateBrand={updateBrand} saveBrand={saveBrand} agencies={agencies}
                                                  isLoadingAgencies={isLoadingAgencies} />
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                                    {!brand ? (
                                        <>
                                            <Skeleton variant="text" animation="wave" width="50%" />
                                            <Skeleton variant="text" animation="wave" width="100%" />
                                        </>
                                    ) : (
                                        <BingEnabled classes={classes} brand={brand} updateBrand={updateBrand} saveBrand={saveBrand} />
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                                    {!brand ? (
                                        <>
                                            <Skeleton variant="text" animation="wave" width="50%" />
                                            <Skeleton variant="text" animation="wave" width="100%" />
                                        </>
                                    ) : (
                                        <EmailAlerts classes={classes} brand={brand} updateBrand={updateBrand} saveBrand={saveBrand} />
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                                    {!brand ? (
                                        <>
                                            <Skeleton variant="text" animation="wave" width="50%" />
                                            <Skeleton variant="text" animation="wave" width="100%" />
                                        </>
                                    ) : (
                                        <DomainUrls classes={classes} brand={brand} updateBrand={updateBrand} saveBrand={saveBrand} />
                                    )}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <SearchTerms classes={classes} type={searchTerms.searchTerms} saveTerms={props.saveTerms}
                                 searchTerms={props.searchTerms === null ? null : props.searchTerms.searchTerms}
                                 count={props.searchTerms === null ? 0 : props.searchTerms.searchTermsCount}
                                 isLoadingSearchTerms={props.isLoadingSearchTerms}
                                 route={`${location.pathname}/${routes.searchTerms.searchTerms.path}`} />
                </Grid>

                <Grid item xs={12}>
                    <SearchTerms classes={classes} type={searchTerms.genericTerms} saveTerms={props.saveTerms}
                                 searchTerms={props.searchTerms === null ? null : props.searchTerms.genericTerms}
                                 count={props.searchTerms === null ? 0 : props.searchTerms.genericTermsCount}
                                 isLoadingSearchTerms={props.isLoadingSearchTerms}
                                 route={`${location.pathname}/${routes.searchTerms.genericTerms.path}`} />
                </Grid>

                <Grid item xs={12}>
                    <SearchTerms classes={classes} type={searchTerms.onLabelTerms} saveTerms={props.saveTerms}
                                 searchTerms={props.searchTerms === null ? null : props.searchTerms.onLabelTerms}
                                 count={props.searchTerms === null ? 0 : props.searchTerms.onLabelTermsCount}
                                 isLoadingSearchTerms={props.isLoadingSearchTerms}
                                 route={`${location.pathname}/${routes.searchTerms.onLabelTerms.path}`} />
                </Grid>

                <Grid item xs={12}>
                    <SearchTerms classes={classes} type={searchTerms.offLabelTerms} saveTerms={props.saveTerms}
                                 searchTerms={props.searchTerms === null ? null : props.searchTerms.offLabelTerms}
                                 count={props.searchTerms === null ? 0 : props.searchTerms.offLabelTermsCount}
                                 isLoadingSearchTerms={props.isLoadingSearchTerms}
                                 route={`${location.pathname}/${routes.searchTerms.offLabelTerms.path}`} />
                </Grid>
            </Grid>
        </div>
    );
}

BrandDetailsComponent.propTypes = {
    brand: PropTypes.object,
    searchTerms: PropTypes.object,
    isLoadingSearchTerms: PropTypes.bool,
    saveBrand: PropTypes.func,
    saveTerms: PropTypes.func,
    updateBrand: PropTypes.func,
    agencies: PropTypes.array
};

export default BrandDetailsComponent;
