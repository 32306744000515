import React from 'react';
import {useHistory} from 'react-router-dom';

import {Button, Card, CardContent, CardHeader, Divider, Grid, makeStyles} from '@material-ui/core';
import routes from 'routes';
import AccessControl, {check} from '../../RBAC/AccessControl';
import rules from '../../../rbac-rules';
import {useSelector} from 'react-redux';
import permissions from '../../../permissions';
import MaterialUITable from '../../Custom/MaterialUITable';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4)
    },
    addButton: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}));

export default function BrandsTableComponent(props) {

    const {brands, isLoading} = props;
    const classes = useStyles();
    let history = useHistory();
    const { currentUser } = useSelector(state => state.users);
    const canEdit = check(rules, currentUser.role, [permissions.brands.detail]);

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Card variant='outlined'>
                        <CardHeader
                            title={routes.brands.pageTitle}
                            subheader='The following brands have been configured for your agency. You can add additional brands or edit brands from this screen.'
                            action={
                                <AccessControl allowedPermissions={[permissions.brands.create]}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.addButton}
                                        onClick={
                                            () => history.push(`${routes.brands.path}/new`)
                                        }
                                    >
                                        Add Brand
                                    </Button>
                                </AccessControl>
                            }
                        />
                        <Divider/>
                        <CardContent className={classes.cardContent}>
                            <MaterialUITable
                                data={brands}
                                isLoading={isLoading === undefined ? true: isLoading}
                                columns={[
                                    {title: 'Brand Name', field: 'brandName'},
                                    {title: 'Pharma Company', field: 'pharmaCompany'},
                                    {
                                        title: 'Search Strategist',
                                        field: 'searchStrategists',
                                        customFilterAndSearch: (term, rowData) => {
                                            if (rowData.searchStrategists !== undefined) {
                                                return rowData.searchStrategists.some(
                                                    (strategist) => strategist.name.toLocaleLowerCase().includes(term.toLocaleLowerCase())
                                                )
                                            }
                                        },
                                        render: rowData => {
                                            if (rowData.searchStrategists !== undefined && rowData.searchStrategists !== null) {
                                                return rowData.searchStrategists.map(
                                                    strategist => strategist.name.trim()
                                                ).join(', ');
                                            }
                                        }
                                    },
                                ]}
                                {...(canEdit && {onRowClick:
                                        ((event, rowData) => {
                                            let brandId = rowData.id;
                                            history.push(`${routes.brands.path}/${brandId}`);
                                        })

                                })}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}
