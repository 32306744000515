import React from 'react';

import BrandsTableComponent from 'components/Brands/List';
import Main from 'components/Layout/Main';
import AccessControl from 'components/RBAC/AccessControl';
import permissions from 'permissions';
import Forbidden from 'components/Forbidden';
import useBrands from 'api/useBrands';

function BrandsContainer() {

    const { isLoading, brands } = useBrands({}, [], true);

    return (
        <Main>
            <AccessControl allowedPermissions={[permissions.brands.list]} noAccess={() => <Forbidden/>}>
                <BrandsTableComponent brands={brands} isLoading={isLoading}/>
            </AccessControl>
        </Main>
    );
}

export default BrandsContainer;
