import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';

import BrandDetailsComponent from 'components/Brands/Details';
import Main from 'components/Layout/Main';

import { successToast, errorToast } from 'actions';
import routes from 'routes';

import AccessControl from 'components/RBAC/AccessControl';
import permissions from 'permissions';
import Forbidden from 'components/Forbidden';
import useBrand from 'api/useBrand';
import useSearchTerms from 'api/useSearchTerms';
import useAgencies from '../../../api/useAgencies';

function BrandDetailsContainer(props) {

    let params = useParams();
    const [brand, setBrand] = useState(null);
    const [newTerms, setNewTerms] = useState([]);
    const { brand: fetchedBrand } = useBrand(params.brandId);
    const { isLoading, agencies } = useAgencies();

    useEffect(() => {
        // Set the brand
        setBrand(fetchedBrand);
    }, [fetchedBrand]);

    const saveBrand = async({ brand, msg }) => {
        try {
            const updatedBrand = {...brand, searchStrategist: brand.searchStrategists.map(value => value.id) };
            if (updatedBrand.brandName === null || updatedBrand.brandName === '') {
                props.errorToast('Brand Name Cannot be Empty');
            } else if (updatedBrand.pharmaCompany === null || updatedBrand.pharmaCompany === 0) {
                props.errorToast('Pharma Company Cannot be Empty');
            } else {
                const response = await axios.post(`${routes.brands.endpoint}/${params.brandId}`, updatedBrand);
                setBrand(response.data.data)
                props.successToast(msg);
            }
        } catch (error) {
            console.log(error);
            props.errorToast('Error Occurred');
        }
    };

    const saveTerms = async ({ terms, msg }) => {
        try {
            await axios.post(`${routes.brands.endpoint}/${params.brandId}/terms`, terms);
            setNewTerms(terms);
            props.successToast(msg);
        } catch (error) {
            console.log(error);
        }
    };

    // Fetch terms for the brand
    const { isLoading: isLoadingSearchTerms, terms } = useSearchTerms(params.brandId, [newTerms]);

    return (
        <Main>
            <AccessControl allowedPermissions={[permissions.brands.detail]} noAccess={() => <Forbidden/>}>
                <BrandDetailsComponent brand={brand} updateBrand={setBrand} saveBrand={saveBrand} saveTerms={saveTerms}
                                       searchTerms={terms} isLoadingSearchTerms={isLoadingSearchTerms}
                                       agencies={agencies} isLoadingAgencies={isLoading} />
            </AccessControl>
        </Main>
    );
}

const mapDispatchToProps = dispatch => ({
    successToast: msg => dispatch(successToast(msg)),
    errorToast: msg => dispatch(errorToast(msg))
});

export default connect(null, mapDispatchToProps)(BrandDetailsContainer);
