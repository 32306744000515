import React, {forwardRef} from 'react';

import {Card, CardContent, CardHeader, Divider, Grid} from '@material-ui/core';

import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from 'react-router-dom';

import palette from 'theme/palette';
import routes from 'routes';
import {format} from 'date-fns';
import MaterialUITable from '../../Custom/MaterialUITable';
import {MTableBodyRow, MTableCell} from 'material-table';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import {ArrowUpward as ArrowUpwardIcon, FilterList as FilterListIcon} from '@material-ui/icons';
import {Skeleton} from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4)
    }
}));

export default function ViolationsTableComponent(props) {

    const {violations} = props;
    const {violationsCount} = props;
    const classes = useStyles();
    let history = useHistory();
    const height = window.innerHeight - 300;

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Card variant='outlined'>
                        {violationsCount !== undefined ? (
                            <CardHeader title={routes.violations.pageTitle}
                                        subheader={violationsCount + ' unread violations'}/>
                        ) : (
                            <CardHeader title={routes.violations.pageTitle}
                                          subheader={<Skeleton variant="text" animation="wave" width="25%" />} />
                        )}
                        <Divider/>
                        <CardContent className={classes.cardContent}>

                            <MaterialUITable
                                options={{
                                    search: false,
                                    filtering: true,
                                    sorting: true,
                                    pagination: true,
                                    pageSizeOptions: [25,50,100],
                                    maxBodyHeight: height,
                                    toolbar: false,
                                    headerStyle: {
                                        color: palette.primary.dark,
                                        fontWeight: '900',
                                    },
                                    emptyRowsWhenPaging: false,
                                    pageSize: 50,
                                    actionsColumnIndex: -1,
                                    rowStyle: rowData => !rowData.viewedByUser ? {color: palette.primary.dark, fontWeight: '900'} : null
                                }}
                                onRowClick={(event, rowData) => {
                                    const violationId = rowData.adId;
                                    history.push(`${routes.violations.path}/${violationId}`);
                                }}
                                components={{
                                    Row: props => (<MTableBodyRow {...props} className={classes.tableRow}/>),
                                    Cell: props => (<MTableCell {...props} className={classes.tableCell}/>),
                                }}
                                isLoading={props.isLoadingViolations}
                                data={violations}
                                columns={[
                                    {title: 'Brand Name', field: 'brandName'},
                                    {title: 'Ad Title', field: 'adHeadline'},
                                    {title: 'Advertiser URL', field: 'adDisplayUrl'},
                                    {
                                        title: 'Search Term', field: 'searchTerms',
                                        render: rowData => {
                                            return rowData.searchTerms.join(', ');
                                        }
                                    },
                                    {title: 'Search Provider', field: 'adProvider'},
                                    {
                                        title: 'Date/Time',
                                        field: 'adCaptureDate',
                                        render: rowData => {
                                            return format(new Date(rowData.adCaptureDate), "MMM d, yyyy 'at' h:mmaaaa");
                                        },
                                        customFilterAndSearch: (term, rowData) =>
                                            rowData.adCaptureDate === null ? false : (format(new Date(rowData.adCaptureDate), "MMM d, yyyy 'at' h:mmaaaa").toLowerCase()).includes(term.toLowerCase())
                                    },
                                ]}
                                icons={{
                                    Filter: forwardRef((props, ref) => <FilterListIcon {...props} ref={ref}/>),
                                    SortArrow: forwardRef((props, ref) => <ArrowUpwardIcon {...props} ref={ref}/>),
                                    FirstPage: forwardRef((props, ref) => <FirstPageIcon {...props} ref={ref}/>),
                                    LastPage: forwardRef((props, ref) => <LastPageIcon {...props} ref={ref}/>),
                                    NextPage: forwardRef((props, ref) => <NavigateNextIcon {...props} ref={ref}/>),
                                    PreviousPage: forwardRef((props, ref) => <NavigateBeforeIcon {...props} ref={ref}/>),
                                }}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}
