import React from 'react';
import {useSelector} from 'react-redux';

import ViolationsTableComponent from 'components/Violations/List';
import Main from 'components/Layout/Main';
import AccessControl from '../../../components/RBAC/AccessControl';
import permissions from '../../../permissions';
import Forbidden from '../../../components/Forbidden';

function ViolationsContainer() {

    const {violations} = useSelector(state => state.violations);
    const {violationsCount} = useSelector(state => state.violationsCount);

    return (
        <Main>
            <AccessControl allowedPermissions={[permissions.violations.list]} noAccess={() => <Forbidden/>}>
                <ViolationsTableComponent violations={violations === undefined ? [] : violations.data} violationsCount={violationsCount}
                                          isLoadingViolations={violations === undefined ? true : violations.isLoading} />
            </AccessControl>
        </Main>
    );
}

export default ViolationsContainer;
